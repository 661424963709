import React from 'react';
import { Tabs, Tab, Divider } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStore,
  faBuildingColumns,
  faFlagUsa,
  faSchool,
  faMoneyBillWave,
  faHandsHelping,
  faPhoneAlt,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import '../customCSS/industries.css';

const industries = [
  {
    title: 'Retail',
    icon: faStore,
    subtitle: 'Unlock the Power of Exceptional Customer Service!',
    description:
      'In the fast-paced world of retail, every interaction counts. ITM enhances your customer experience with 24/7 support, personalized shopping assistance, and efficient management of high call volumes. Let us help you build lasting customer loyalty and drive sales growth.',
  },
  {
    title: 'Government',
    icon: faBuildingColumns,
    subtitle: 'Empower Your Community with Seamless Communication!',
    description:
      'Government agencies need to connect effectively with citizens. ITM streamlines public interactions, manages inquiries with ease, and ensures compliance. Elevate public engagement and trust with our expert support, making your services more accessible and responsive.',
  },
  {
    title: 'Political',
    icon: faFlagUsa,
    subtitle: 'Engage and Mobilize Your Constituents Like Never Before!',
    description:
      'In the political arena, effective communication is key. ITM supports your campaign with targeted voter outreach, efficient constituent communication, and insightful polling services. Let us help you craft strategies that resonate and drive results.',
  },
  {
    title: 'Education',
    icon: faSchool,
    subtitle: 'Enhance Student Engagement and Support!',
    description:
      'Education is all about connection. ITM assists institutions in managing inquiries from prospective students, streamlining enrollment processes, and providing ongoing support for current students. Elevate your communication and foster a thriving learning environment.',
  },
  {
    title: 'Finance/Banking',
    icon: faMoneyBillWave,
    subtitle: 'Transform Customer Service into a Competitive Advantage!',
    description:
      'In the finance sector, trust and accessibility are paramount. ITM provides timely support, expertly manages account inquiries, and facilitates secure communications. Enhance customer retention and satisfaction with our tailored solutions designed for your unique needs.',
  },
  {
    title: 'Charity/Non-Profit',
    icon: faHandsHelping,
    subtitle: 'Amplify Your Mission and Engage Donors Effectively!',
    description:
      'In the non-profit sector, every interaction can make a difference. ITM helps you manage donor communications, run impactful fundraising campaigns, and mobilize volunteers efficiently. Boost engagement and resources to further your mission with our dedicated support.',
  },
  {
    title: 'Telecommunication',
    icon: faPhoneAlt,
    subtitle: 'Stay Connected with Exceptional Customer Care!',
    description:
      'In the telecommunications industry, customer satisfaction is key to reducing churn. ITM offers reliable support for troubleshooting, billing inquiries, and service management. Let us help you enhance your customer experience and keep your clients connected.',
  },
  {
    title: 'Publishing',
    icon: faBookOpen,
    subtitle: 'Maximize Reader Engagement and Satisfaction!',
    description:
      'In the publishing world, connecting with your audience is crucial. ITM manages subscriber inquiries, facilitates seamless order processing, and gathers valuable reader feedback. Elevate your customer experience and drive engagement with our expert services.',
  },
];

const IndustryTabs = () => {
  return (
    <div className='container'>
      <div className='section-heading'>Industries</div>
      <div className='pricing-headings'>
        <h2 className='heading-secondary'>
          Tailored Solutions for Every Industry
        </h2>
        <p className='feature-text'>
          Experience exceptional service with our industry-specific solutions.
          No matter your sector, we provide adaptable call center services
          designed to meet your unique challenges and goals. From startups to
          established enterprises, our team is ready to deliver customized
          support that drives success. Reach out to us today for a consultation
          tailored to your industry needs!
        </p>
      </div>
      <div className='industries-wrapper'>
        <Tabs
          variant='light'
          classNames={{
            base: 'industries-base', // Custom class for the base tab
            tabList: 'industries-tablist', // Custom class for the tab list
            tab: 'industries-tab', // Custom class for each tab
            tabContent: 'industries-tabcontent', // Custom class for tab content
            cursor: 'industries-cursor', // Custom class for cursor (if needed)
            panel: 'industries-panel', // Custom class for panel
          }}>
          {industries.map((industry, index) => (
            <Tab
              key={index}
              title={
                <>
                  <FontAwesomeIcon
                    icon={industry.icon}
                    size='4x'
                    fixedWidth
                  />
                  <p className='industries-title'>{industry.title}</p>
                </>
              }>
              <div>
                <h2 className='industries-subtitle'>{industry.subtitle}</h2>
                <p className='industries-description'>{industry.description}</p>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <Divider className='industries-divider' />
      <p className='industries-pitch'>
        <strong>Partner with ITM today</strong> to revolutionize your customer
        interactions, streamline operations, and accelerate your growth. Let's
        create a winning strategy together!
      </p>
    </div>
  );
};

export default IndustryTabs;
