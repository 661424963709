import React from 'react';
import { Card, CardHeader, CardBody } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck,
  faHeadset,
  faUserSlash,
  faCommentSms,
  faCrosshairs,
  faTools,
  faEnvelope,
  faCommentDots,
  faChartLine,
  faPhoneAlt,
  faShareAlt,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';
import '../customCSS/serviceCards.css';

const data = [
  {
    title: 'Appointment Setting',
    description:
      'Comprehensive B2B and B2C appointment scheduling designed to maximize lead conversion. Start uncovering new business opportunities within just weeks.',
    icon: faCalendarCheck,
  },
  {
    title: 'Inbound Customer Care',
    description:
      'With over 25 years of expertise in delivering exceptional customer care, ITM is dedicated to providing your customers with a remarkable and satisfying experience at every touchpoint.',
    icon: faHeadset,
  },
  {
    title: 'Agentless Engagement',
    description:
      'Utilizing the Genesys Cloud CX™ platform, we offer automated customer interactions, including delivery notifications and in-store pickup alerts, enhancing customer communication effortlessly.',
    icon: faUserSlash,
  },
  {
    title: 'SMS/MMS',
    description:
      'Utilize the immediacy and personal touch of SMS/MMS to connect directly with your audience. Engage in real-time conversations and inspire action with the simplicity and effectiveness of SMS/MMS messaging.',
    icon: faCommentSms,
  },
  {
    title: 'Lead Generation',
    description:
      'Drive growth with targeted lead generation strategies that identify and engage potential clients, ensuring a steady pipeline of opportunities.',
    icon: faCrosshairs,
  },
  {
    title: 'Technical Support',
    description:
      'Our skilled agents provide round-the-clock technical support, ensuring quick resolutions and maintaining customer satisfaction.',
    icon: faTools,
  },
  {
    title: 'Email Support',
    description:
      'Deliver timely and effective email support, handling inquiries with professionalism and a personal touch to enhance customer experience.',
    icon: faEnvelope,
  },
  {
    title: 'Chat Support',
    description:
      'Engage customers in real-time with our chat support services, offering instant assistance and solutions to improve satisfaction.',
    icon: faCommentDots,
  },
  {
    title: 'Surveys and Feedback',
    description:
      'Collect valuable insights through customer surveys and feedback, helping you refine services and enhance customer relationships.',
    icon: faChartLine,
  },
  {
    title: 'Virtual Receptionist',
    description:
      'Provide a professional first impression with our virtual receptionist services, ensuring all calls are answered and routed efficiently.',
    icon: faPhoneAlt,
  },
  {
    title: 'Social Media',
    description:
      'Enhance your online presence with our social media management services, engaging with customers and driving brand loyalty.',
    icon: faShareAlt,
  },
  {
    title: 'Conversational Bots',
    description:
      'Our Conversational Bot technology offers self-service options that emulate the live agent experience. Engage your audience seamlessly and provide instant support while enhancing efficiency and reducing costs.',
    icon: faRobot,
  },
];

const ServiceCards = () => {
  return (
    <div className='container'>
      <div className='service-heading'>Omnichannel CX</div>
      <div className='section-services-container'>
        <div className='service-headings'>
          <h2 className='heading-secondary'>
            Your Partner in Unparalleled Customer Satisfaction
          </h2>
          <p className='feature-text '>
            At ITM, we understand that every interaction counts. Our
            comprehensive range of services combines cutting-edge technology
            with a personal touch, ensuring that your customers receive the
            exceptional care they deserve at every stage of their journey.
          </p>
        </div>
      </div>
      <div className='special-container'>
        <div className='card-container'>
          {data.map((item, index) => (
            <Card
              key={index}
              shadow='md'
              className='service-card'>
              <CardHeader>
                <p className='card-title'>{item.title}</p>
                <FontAwesomeIcon
                  icon={item.icon}
                  size='xl'
                />
              </CardHeader>
              <CardBody>
                <p>{item.description}</p>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceCards;
