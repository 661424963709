import React from 'react';
import { Link } from 'react-scroll';
import Bubbles from './bubbles';
import '../customCSS/hero.css';

const Hero = () => {
  return (
    <section className='hero'>
      <div className='hero-content'>
        <p className='hero-subtitle'>White Glove Contact Center Outsourcing</p>
        <h1 className='hero-title'>
          Redefining Customer eXperience Excellence
        </h1>
        <p className='hero-description'>
          Meet <strong>ITM</strong>, a boutique contact center BPO based in Ohio
          dedicated to delivering scalable, results-driven solutions that
          accelerate business growth through tailored customer engagement
          strategies.
        </p>
        <div className='hero-buttons'>
          <Link
            href='#'
            to='contact'
            smooth={true}
            duration={500}
            className='cta-button'>
            Get Started
          </Link>
          <Link
            href='#'
            to='how-it-works'
            smooth={true}
            duration={500}
            className='cta-button-secondary'>
            Learn More
          </Link>
        </div>
      </div>
      <Bubbles />
    </section>
  );
};

export default Hero;
