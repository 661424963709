import React from 'react';
import '../customCSS/bubble2.css';

const Bubbles = () => {
  return (
    <section className='Bubbles'>
      {/* Floating Bubbles */}
      <div className='bubble bubble1'></div>
      <div className='bubble-reverse bubble2'></div>
      <div className='bubble bubble3'></div>
      <div className='bubble-reverse bubble4'></div>
      <div className='bubble bubble5'></div>
    </section>
  );
};

export default Bubbles;
