import React from 'react';
import Hero from '../components/hero';
import ServiceCards from '../components/serviceCards';
import Pricing from '../components/pricing';
import LogoBanner from '../components/logoBanner';
import HowItWorks from '../components/howItWorks';
import IndustryTabs from '../components/industries';
import Testimonials from '../components/testimonials';
import CallToAction from '../components/callToAction';
import '../customCSS/landingPage.css';

function LandingPage() {
  return (
    <div>
      <section
        id='hero'
        className='hero-section'>
        <Hero />
      </section>

      <section
        id='logo-banner'
        className='logo-banner-section'>
        <LogoBanner />
      </section>

      <section
        id='how-it-works'
        className='how-it-works-section'>
        <HowItWorks />
      </section>

      <section
        id='services'
        className='services-section'>
        <ServiceCards />
      </section>

      <section
        id='pricing'
        className='pricing-section'>
        <Pricing />
      </section>

      <section
        id='testimonials'
        className='testimonials-section'>
        <Testimonials />
      </section>

      <section
        id='industries'
        className='industries-section'>
        <IndustryTabs />
      </section>

      <section
        id='contact'
        className='cta-section'>
        <CallToAction />
      </section>
    </div>
  );
}

export default LandingPage;
