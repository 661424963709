import React from 'react';
import '../customCSS/termsAndConditions.css';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <div className='policy-container'>
      <h1 className='policy-title'>Terms and Conditions</h1>
      <p className='policy-effective-date'>Effective Date: 2024-10-31</p>

      <p className='policy-intro'>
        Welcome to ITM Marketing, Inc. (“ITM,” “we,” “us,” or “our”). These
        Terms and Conditions (“Terms”) govern your use of our services. By using
        our services, you agree to these Terms. Please read them carefully.
      </p>

      <section className='policy-section'>
        <h2 className='policy-heading'>1. Services Provided</h2>
        <p className='policy-text'>
          ITM Marketing provides customer support, technical assistance, and
          related services via phone, email, chat, and other communication
          channels (“Services”). While we strive to provide accurate
          information, we do not guarantee the completeness, reliability, or
          timeliness of information provided through our Services.
        </p>
      </section>

      <section className='policy-section'>
        <h2 className='policy-heading'>2. User Responsibilities</h2>
        <p className='policy-text'>By using our Services, you agree:</p>
        <ul className='policy-list'>
          <li>To provide accurate and complete information when requested.</li>
          <li>
            Not to use our Services for illegal, abusive, or harassing purposes.
          </li>
          <li>
            To respect our agents, representatives, and any other individuals
            involved in providing Services.
          </li>
        </ul>
      </section>

      <section className='policy-section'>
        <h2 className='policy-heading'>3. Privacy Policy</h2>
        <p className='policy-text'>
          Your privacy is important to us. Please refer to our{' '}
          <Link
            to='/privacy-policy'
            className='policy-link'>
            Privacy Policy
          </Link>{' '}
          for details on how we collect, use, and protect your information. By
          using our Services, you consent to the practices described in the
          Privacy Policy.
        </p>
      </section>

      <section className='policy-section'>
        <h2 className='policy-heading'>4. Confidentiality and Security</h2>
        <p className='policy-text'>
          We take reasonable steps to protect the confidentiality of your
          information. However, ITM cannot guarantee absolute confidentiality,
          especially in circumstances beyond our control, such as unauthorized
          access or data breaches.
        </p>
      </section>

      <section className='policy-section'>
        <h2 className='policy-heading'>5. Limitation of Liability</h2>
        <p className='policy-text'>
          ITM Marketing is not liable for any damages arising from:
          <ul className='policy-list'>
            <li>The inaccuracy or incompleteness of information provided.</li>
            <li>Service interruptions or delays.</li>
            <li>Unauthorized access to or alteration of your data.</li>
          </ul>
          In no event shall ITM be liable for any direct, indirect, incidental,
          special, consequential, or punitive damages arising from your use of
          our Services, even if we have been advised of the possibility of such
          damages. Our liability shall, in any case, be limited to the extent
          permitted by applicable law.
        </p>
      </section>

      <section className='policy-section'>
        <h2 className='policy-heading'>6. Disclaimer of Warranties</h2>
        <p className='policy-text'>
          ITM Marketing makes no warranties or guarantees about the accuracy,
          reliability, or availability of our Services. All Services are
          provided “as is” and “as available,” without warranty of any kind,
          either express or implied. This includes, but is not limited to,
          implied warranties of merchantability, fitness for a particular
          purpose, and non-infringement.
        </p>
      </section>

      <section className='policy-section'>
        <h2 className='policy-heading'>
          7. Governing Law and Dispute Resolution
        </h2>
        <p className='policy-text'>
          These Terms are governed by the laws of the State of Ohio,
          specifically Coshocton County. Any disputes arising from these Terms
          or your use of our Services shall be resolved through arbitration or
          in the courts of Ohio, as applicable.
        </p>
      </section>

      <section className='policy-section'>
        <h2 className='policy-heading'>8. Modifications to Terms</h2>
        <p className='policy-text'>
          ITM Marketing reserves the right to modify these Terms at any time.
          Changes will be effective upon posting on our website. By continuing
          to use our Services, you agree to the revised Terms.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
