import Marquee from "react-fast-marquee";
import "../customCSS/logoBanner.css";

const logos = [
  { src: "/companyLogos/ACLULogo.svg", alt: "ACLU", width: 200, height: 80 },
  {
    src: "/companyLogos/ClevelandCLogo.svg",
    alt: "Cleveland Clinic",
    width: 240,
    height: 60,
  },
  {
    src: "/companyLogos/DAVLogo.png",
    alt: "DAV",
    width: 180,
    height: 90,
  },
  {
    src: "/companyLogos/EasyDriveLogo.png",
    alt: "Easy Drive",
    width: 250,
    height: 100,
  },
  {
    src: "/companyLogos/EmpireTodayLogo.svg",
    alt: "Empire Today",
    width: 220,
    height: 80,
  },
  {
    src: "/companyLogos/FDICLogo.svg",
    alt: "FDIC",
    width: 210,
    height: 70,
  },
  {
    src: "/companyLogos/JuniorsLogo.png",
    alt: "Junior's",
    width: 230,
    height: 90,
  },
  {
    src: "/companyLogos/KirklandsLogo.svg",
    alt: "Kirkland's Home",
    width: 220,
    height: 80,
  },
  {
    src: "/companyLogos/MonroMBSLogo.svg",
    alt: "Monro Muffler Brake & Service",
    width: 240,
    height: 70,
  },
  {
    src: "/companyLogos/SemprisLogo.png",
    alt: "Sempris",
    width: 180,
    height: 80,
  },
  {
    src: "/companyLogos/osu.svg",
    alt: "The Ohio State University",
    width: 200,
    height: 80,
  },
  {
    src: "/companyLogos/PRKLogo.png",
    alt: "Park National",
    width: 220,
    height: 90,
  },
  {
    src: "/companyLogos/SBALogo.svg",
    alt: "Small Business Administration",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/SchoolSLogo.png",
    alt: "School Specialty",
    width: 230,
    height: 80,
  },
  {
    src: "/companyLogos/RedCrossLogo.svg",
    alt: "Red Cross",
    width: 220,
    height: 90,
  },
  {
    src: "/companyLogos/BaLLogo.svg",
    alt: "Bausch + Lomb",
    width: 220,
    height: 70,
  },
  {
    src: "/companyLogos/CrayolaLogo.png",
    alt: "Crayola",
    width: 250,
    height: 100,
  },
  {
    src: "/companyLogos/FechLogo.png",
    alt: "Fechheimer",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/MrMLogo.svg",
    alt: "Mr. M",
    width: 200,
    height: 80,
  },
  {
    src: "/companyLogos/UniSLogo.png",
    alt: "Uniscience",
    width: 230,
    height: 80,
  },
  {
    src: "/companyLogos/ScrantonLogo.svg",
    alt: "Scranton",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/MonCheriLogo.svg",
    alt: "Mon Cheri",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/PentonLogo.png",
    alt: "Penton",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/SummaHealthLogo.png",
    alt: "Summa Health",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/AcurianLogo.png",
    alt: "Acurian",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/LibertyPowerLogo.png",
    alt: "Liberty Power",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/AragonResearchLogo.png",
    alt: "Aragon Research",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/AmBestLogo.png",
    alt: "AM Best",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/CrainCommLogo.png",
    alt: "Crain Communications",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/PrincessHouseLogo.svg",
    alt: "Princess House",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/MarleyDrugLogo.png",
    alt: "Marley Drug",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/InfinityLogo.png",
    alt: "Nfinity Athletic",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/ContrixLogo.png",
    alt: "Contrix Inc.",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/AltafiberLogo.svg",
    alt: "Altafiber",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/Essentials360Logo.svg",
    alt: "Essentials 360",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/EtechLogo.svg",
    alt: "Etech Global Services",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/IHSLogo.svg",
    alt: "Infinity Home Services",
    width: 240,
    height: 80,
  },
  {
    src: "/companyLogos/UHLogo.png",
    alt: "University Hospitals",
    width: 240,
    height: 80,
  },
];

const LogoBanner = () => {
  return (
    <div className="trusted-by">
      <h2 className="trusted-by-title">Our Valued Partners</h2>
      <div className="logo-banner">
        <Marquee
          gradient={false}
          speed={50}
          pauseOnHover={true}
          autoFill={true}
        >
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt}
              width={logo.width}
              height={logo.height}
              className="ticker-img"
            />
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default LogoBanner;
