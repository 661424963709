import React from 'react';
import '../customCSS/aboutUs.css';

const AboutUs = () => {
  return (
    <div className='about-container'>
      <h1 className='about-title'>About ITM Marketing, Inc.</h1>
      <div className='about-content'>
        <img
          src='/buildingimg.png'
          alt='ITM Marketing Office'
          className='about-image'
        />
        <div className='about-text'>
          <p>
            ITM Marketing, Inc. stands as a cutting-edge Customer Interaction
            Center nestled in the heart of Central Ohio, charting its journey
            since 1996. Specializing in both inbound and outbound interaction
            solutions, we remain at the forefront of innovation, leveraging
            cutting edge technology to deliver unparalleled service. Our
            solutions span across various mediums, seamlessly integrated with
            shared databases to ensure fluid information flow and heightened
            efficiency.
          </p>
          <p>
            Unlike traditional legacy architectures, which often involve
            disparate systems, our approach consolidates transactions onto a
            unified interaction platform. Regardless of the communication
            channel—be it phone, fax, web, or email—clients benefit from a
            unified experience, underscored by unwavering consistency and
            reliability.
          </p>
          <p>
            Handling nearly 2 million customer interactions annually, our
            seasoned team upholds the integrity of every contact, reflecting our
            firm commitment to excellence. At ITM, we take immense pride in our
            track record of top-tier performance, recognizing that staying ahead
            entails not only embracing cutting-edge technology but also deeply
            understanding our clients' evolving needs.
          </p>
          <p>
            Central to our approach is our persistent focus on customer
            satisfaction. Through our state-of-the-art CX platform and decades
            of industry expertise, we ensure that every interaction with your
            customers is characterized by professionalism, efficiency, and a
            genuine commitment to exceeding expectations.
          </p>
          <p>
            At ITM Marketing, we understand that success is not just measured in
            transactions but in the lasting impressions we leave with each
            interaction. Trust us to be the partner that amplifies your brand
            voice, cultivates loyalty, and drives sustainable growth.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
