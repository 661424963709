import React from 'react';
import '../customCSS/callToAction.css';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from '@nextui-org/react';

const CallToAction = () => {
  // Form Fields
  const [fullName, setFullName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [companySize, setCompanySize] = React.useState('');
  const [monthlyBudget, setMonthlyBudget] = React.useState('');
  const [industry, setIndustry] = React.useState('');
  const [additionalInfo, setAdditionalInfo] = React.useState('');

  // Loading State
  const [loading, setLoading] = React.useState(false);

  // Form Submission Success
  const [success, setSuccess] = React.useState(false);

  /**
   * Handles the form submission.
   *
   * @param {Event} e - The event object.
   * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
   *
   * @async
   * @function handleSubmit
   *
   * @throws {Error} Throws an error if the form submission fails.
   */
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    try {
      // Validate required fields
      if (
        !fullName ||
        !email ||
        !jobTitle ||
        !phoneNumber ||
        !companyName ||
        !companySize ||
        !monthlyBudget ||
        !industry
      ) {
        toast.error('Please fill in all required fields.');
        setLoading(false);
        return;
      }

      const formData = {
        fullName,
        email,
        jobTitle,
        phoneNumber,
        companyName,
        companySize,
        monthlyBudget,
        industry,
        additionalInfo: additionalInfo || 'Did Not Provide',
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/landingPage/submit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || `Server Error: ${response.status}`
        );
      }

      // Show success notification
      toast.success('Form submitted successfully');
      setSuccess(true); // Set success state to true
    } catch (error) {
      console.error('Error submitting form:', error.message);

      // Show error notification
      toast.error(`Failed to submit form: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='cta-section'>
      <ToastContainer />
      <div className='cta-container'>
        <div className='cta-content'>
          <h2>Get started with ITM</h2>
          {success ? (
            <div className='dflex center-content full-height success-message'>
              <h3>
                Thank you for your submission! We will review your request and
                get back to you as soon as possible.
              </h3>
            </div>
          ) : (
            <>
              <p>
                Discover how ITM can elevate your customer interactions and
                enhance your brand experience. Start by telling us more about
                your company.
              </p>

              <form onSubmit={handleSubmit} className='cta-form'>
                <div className='cta-form-grid'>
                  <div className='cta-form-group'>
                    <label>Full Name</label>
                    <input
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      type='text'
                      disabled={loading}
                      placeholder='John Smith'
                    />
                  </div>
                  <div className='cta-form-group'>
                    <label>Email Address</label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type='email'
                      disabled={loading}
                      placeholder='me@example.com'
                    />
                  </div>
                  <div className='cta-form-group'>
                    <label>Job Title</label>
                    <input
                      onChange={(e) => setJobTitle(e.target.value)}
                      value={jobTitle}
                      type='text'
                      disabled={loading}
                      placeholder='Your Job Title'
                    />
                  </div>
                  <div className='cta-form-group'>
                    <label>Phone Number</label>
                    <input
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      type='tel'
                      disabled={loading}
                      placeholder='+1'
                    />
                  </div>
                  <div className='cta-form-group'>
                    <label>Company Name</label>
                    <input
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                      type='text'
                      disabled={loading}
                      placeholder='Your Company Name'
                    />
                  </div>
                  <div className='cta-form-group'>
                    <label htmlFor='company-size'>Company Size</label>
                    <select
                      disabled={loading}
                      id='company-size'
                      value={companySize}
                      onChange={(e) => setCompanySize(e.target.value)}
                    >
                      <option>Select company size</option>
                      <option>1-10</option>
                      <option>11-50</option>
                      <option>51-200</option>
                      <option>201-500</option>
                      <option>501+</option>
                    </select>
                  </div>
                  <div className='cta-form-group'>
                    <label htmlFor='monthly-budget'>
                      Available Monthly Budget
                    </label>
                    <select
                      disabled={loading}
                      id='monthly-budget'
                      value={monthlyBudget}
                      onChange={(e) => setMonthlyBudget(e.target.value)}
                    >
                      <option>Select budget</option>
                      <option>$0-$1000</option>
                      <option>$1000-$5000</option>
                      <option>$5000+</option>
                    </select>
                  </div>
                  <div className='cta-form-group'>
                    <label htmlFor='industry'>Industry</label>
                    <select
                      disabled={loading}
                      id='industry'
                      value={industry}
                      onChange={(e) => setIndustry(e.target.value)}
                    >
                      <option>Select industry</option>
                      <option>Retail</option>
                      <option>Technology</option>
                      <option>Finance</option>
                      <option>Healthcare</option>
                      <option>Education</option>
                      <option>Publishing</option>
                      <option>Non-Profit</option>
                      <option>Political</option>
                    </select>
                  </div>
                  <div
                    disabled={loading}
                    className='cta-form-group'
                    style={{ gridColumn: 'span 2' }}
                  >
                    <label htmlFor='additional-info'>Additional Info</label>
                    <textarea
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                      value={additionalInfo}
                      id='additional-info'
                      rows='4'
                      placeholder='Enter any additional information'
                    ></textarea>
                  </div>
                </div>
                <Button
                  isLoading={loading}
                  type='submit'
                  className='cta-submit'
                  color='primary'
                >
                  Get Started
                </Button>
                <p className='cta-terms'>
                  By submitting this form, you agree to our{' '}
                  <Link
                    to='/privacy-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy Policy
                  </Link>
                </p>
              </form>
            </>
          )}
        </div>
        <div className='cta-image'>
          <img src='getStarted.webp' alt='Customer Experience' />
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
