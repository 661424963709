import React from 'react';
import '../customCSS/pricing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { scroller } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';

const Pricing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Detect when the pricing section is in view
  const { ref: pricingSectionRef, inView: sectionVisible } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Trigger when 10% of the section is in view
  });

  /**
   * Handles navigation to a target element on the page.
   * If the current path is the home page ('/'), it scrolls to the target element smoothly.
   * Otherwise, it navigates to the home page and sets the state to scroll to the target element.
   *
   * @param {string} target - The ID of the target element to scroll to.
   */
  const handleNavigation = (target) => {
    if (location.pathname === '/') {
      scroller.scrollTo(target, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } else {
      navigate('/', { state: { scrollTo: target } });
    }
  };

  return (
    <div className='container'>
      <div className='section-heading'>Pricing</div>
      <div className='pricing-headings'>
        <h2 className='heading-secondary'>
          Customized Pricing for Maximum Value
        </h2>
        <p className='feature-text'>
          Get the best value for your business with our customized pricing
          packages. Whether you're a small business or a large enterprise, our
          call center services are designed to scale with your needs. Contact us
          for a personalized consultation.
        </p>
      </div>

      <div
        ref={pricingSectionRef}
        className='pricing-table'>
        {/* Initial Plan */}
        <div
          className={`pricing-card price-initial ${
            sectionVisible ? 'visible' : ''
          }`}>
          <h3 className='heading-tertiary pricing-heading'>Growth Plan</h3>
          <div className='pricing-details'>
            {/* Features available in Initial Plan */}
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Inbound Customer Care
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Cold Calls / Voicemails
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Appointment Setting
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Email Support
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Reporting and Analytics
            </span>
            <span>
              <FontAwesomeIcon
                icon={faTimes}
                className='icon icon-unavailable'
              />{' '}
              Conversational Bots
            </span>
            <span>
              <FontAwesomeIcon
                icon={faTimes}
                className='icon icon-unavailable'
              />{' '}
              Technical Support
            </span>
            <span>
              <FontAwesomeIcon
                icon={faTimes}
                className='icon icon-unavailable'
              />{' '}
              Lead Generation
            </span>
            <span>
              <FontAwesomeIcon
                icon={faTimes}
                className='icon icon-unavailable'
                color='red'
              />{' '}
              SDR Outsourcing
            </span>
            <button
              className='cta-button-secondary'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('contact');
              }}>
              Get a Quote
            </button>
          </div>
        </div>

        {/* Professional Plan */}
        <div
          className={`pricing-card enterprise ${
            sectionVisible ? 'visible' : ''
          }`}>
          <h3 className='heading-tertiary pricing-heading'>Enterprise</h3>
          <div className='pricing-details'>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Inbound Customer Care
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Cold Calls / Voicemails
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Appointment Setting
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Email Support
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Reporting and Analytics
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Conversational Bots
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Technical Support
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              Lead Generation
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCheck}
                className='icon'
              />{' '}
              SDR Outsourcing
            </span>
            <button
              className='cta-button'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('contact');
              }}>
              Get a Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
