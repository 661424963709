import React from 'react';
import '../customCSS/meetTheTeam.css';

// Example team data
const teamMembers = [
  {
    name: 'Bruce Collen',
    role: 'Chief Financial Officer',
    description:
      'Drawing on solid financial experience to support steady growth.',
  },
  {
    name: 'Jeff Afterkirk',
    role: 'VP, Call Center Operations',
    description:
      'Focused on improving processes and enhancing customer experiences.',
  },
  {
    name: 'Theressa Birkhimer',
    role: 'VP, Human Resources',
    description:
      'Committed to creating a supportive and engaging workplace culture.',
  },
  {
    name: 'Steven Trifelos',
    role: 'VP, Strategic Partnerships',
    description:
      'Developing collaborative relationships to move us forward together.',
  },
  {
    name: 'Megan Matz',
    role: 'Director, Client Services',
    description:
      'Striving to deliver reliable, high‐quality service to our clients.',
  },
  {
    name: 'Rebecca Oswald',
    role: 'Manager, Human Resources',
    description: 'Helping foster employee engagement and well‐being.',
  },
  {
    name: 'Branson Berry',
    role: 'Director, Information Technology',
    description: 'Leveraging technology solutions to meet our evolving needs.',
  },
  {
    name: 'Matthew Henderson',
    role: 'Call Center Manager',
    description: 'Dedicated to delivering exceptional customer experiences.',
  },
  {
    name: 'Jadison Strupe',
    role: 'Assistant, Human Resources',
    description: 'Supporting our HR initiatives and employee programs.',
  },

  {
    name: 'Steven McGinnis',
    role: 'Senior Full-Stack Developer',
    description:
      'Dedicated to creating dependable software solutions that meet real-world needs.',
  },
  {
    name: 'Lisa Cox',
    role: 'CX Training & Quality Supervisor',
    description: 'Passionate about helping our team learn and grow.',
  },
  {
    name: 'Courtney Dudte',
    role: 'CX Training & Quality Assistant',
    description: 'Helping our team deliver the best possible service.',
  },
];

// Helper to get initials (e.g. "LF" for "Larry Farrell")
const getInitials = (name) => {
  return name
    .split(' ')
    .map((part) => part[0])
    .join('');
};

const MeetTheTeam = () => {
  return (
    <section className='meet-container'>
      <h2>Meet The Team</h2>
      <p className='meet-intro'>
        We’re a group of dedicated professionals committed to excellence.
      </p>

      {/* Special card for Larry */}
      <div className='single-featured'>
        <div className='team-card featured-card'>
          <div className='initial-circle'>{getInitials('Larry Farrell')}</div>
          <h3>Larry Farrell</h3>
          <h4>President & CEO</h4>
          <p>Visionary leader with a passion for innovation.</p>
        </div>
      </div>

      <div className='team-grid'>
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className='team-card'
            style={{ animationDelay: `${index * 0.08}s` }}
          >
            <div className='initial-circle'>{getInitials(member.name)}</div>
            <h3>{member.name}</h3>
            <h4>{member.role}</h4>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MeetTheTeam;
