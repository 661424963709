import React, { useState, useEffect } from 'react';
import { scroller } from 'react-scroll';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import '../customCSS/navigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

function Navigation() {
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // Scroll to section if coming from a different page
  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  // Sticky navigation only on the homepage
  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        const heroSection = document.getElementById('hero');
        if (heroSection) {
          const heroBottom = heroSection.getBoundingClientRect().bottom;
          setIsSticky(heroBottom <= 0);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      setIsSticky(false);
    }
  }, [location.pathname]);

  /**
   * Handles navigation to a target element on the page.
   * If the current location is the home page ('/'), it scrolls to the target element smoothly.
   * Otherwise, it navigates to the home page and sets the state to scroll to the target element.
   * After navigating, it toggles the navigation menu to close it.
   *
   * @param {string} target - The ID of the target element to scroll to.
   */
  const handleNavigation = (target) => {
    if (location.pathname === '/') {
      scroller.scrollTo(target, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } else {
      navigate('/', { state: { scrollTo: target } });
    }
    toggleNav(); // Close the nav after navigating
  };

  /**
   * Handles the click event for the home and logo elements.
   * Navigates to the home page, scrolls to the top smoothly, and closes the navigation menu.
   */
  const handleHomeAndLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsOpen(false); // Close the nav on logo click
  };

  /**
   * Toggles the navigation menu open or closed.
   * It inverses the current state of `isOpen`.
   */
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`nav-container ${isSticky ? 'nav-sticky' : ''}`}>
      <nav className={`main-nav ml-1 mr-1 ${isOpen ? 'nav-open' : ''}`}>
        <img
          onClick={handleHomeAndLogoClick}
          src='itmlogo.svg'
          alt='ITM Marketing'
          className='logo'
        />

        <ul className='main-nav-list'>
          <li>
            <RouterLink
              to='/'
              onClick={() => {
                handleHomeAndLogoClick();
              }}
              className='main-nav-link'>
              Home
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to='/'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('how-it-works');
              }}
              className='main-nav-link'>
              How It Works
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to='/'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('services');
              }}
              className='main-nav-link'>
              Omnichannel CX
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to='/'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('pricing');
              }}
              className='main-nav-link'>
              Pricing
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to='/'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('testimonials');
              }}
              className='main-nav-link'>
              Why Choose ITM
            </RouterLink>
          </li>
          <li>
            <RouterLink
              to='/'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('industries');
              }}
              className='main-nav-link'>
              Industries
            </RouterLink>
          </li>
          <li>
            <RouterLink
              href='#'
              to='/'
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('contact');
              }}
              className='cta-button hidden'>
              Get a Quote
            </RouterLink>
          </li>
        </ul>
        <RouterLink
          href='#'
          to='/'
          onClick={(e) => {
            e.preventDefault();
            handleNavigation('contact');
          }}
          className='cta-button non-mobile'>
          Get a Quote
        </RouterLink>
        <button
          className='btn-mobile-nav'
          onClick={toggleNav}>
          <FontAwesomeIcon
            icon={isOpen ? faXmark : faBars}
            className='icon-mobile-nav'
          />
        </button>
      </nav>
    </div>
  );
}

export default Navigation;
