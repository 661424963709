// src/Routes.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './views/landingPage';
import PrivacyPolicy from './views/privacyPolicy';
import TermsAndConditions from './views/termsAndConditions';
import AboutUs from './views/aboutUs';
import MeetTheTeam from './views/meetTheTeam';

function AppRoutes() {
  return (
    <Routes>
      <Route
        path='/'
        element={<LandingPage />}
      />
      <Route
        path='/privacy-policy'
        element={<PrivacyPolicy />}
      />
      <Route
        path='/terms-and-conditions'
        element={<TermsAndConditions />}
      />
      <Route
        path='/about-us'
        element={<AboutUs />}
      />
      <Route
        path='/meet-the-team'
        element={<MeetTheTeam />}
      />
      <Route
        path='/resources/pdf/KirklandsCaseJan2022.pdf'
        element={
          <Navigate
            to='/static/resources/pdf/KirklandsCaseJan2022.pdf'
            replace
          />
        }
      />
      <Route
        path='*'
        element={
          <Navigate
            to='/'
            replace
          />
        }
      />
    </Routes>
  );
}

export default AppRoutes;
