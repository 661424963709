import React from 'react';
import '../customCSS/howItWorks.css';

const HowItWorks = () => {
  return (
    <div className='container'>
      <div className='section-heading'>How it Works</div>
      <div className='pricing-headings'>
        <h2 className='heading-secondary'>
          Streamlined Process for Seamless Integration
        </h2>
      </div>
      <section className='how-it-works'>
        <div className='steps-container'>
          <div className='info-block'>
            <h3 className='how-it-works-title'>Contact us</h3>
            <p>
              Reach out to our dedicated team, ready to listen and provide
              personalized solutions tailored to your goals.
            </p>
          </div>

          <div className='dividing-line'>
            <span>1</span>
          </div>

          <div className='illustration-box'>
            <img
              src='/illustrations/ContactUs.svg'
              alt='Contact us'
            />
          </div>
        </div>

        <div className='steps-container'>
          <div className='illustration-box'>
            <img
              src='/illustrations/Exams.svg'
              alt='Identify needs'
            />
          </div>

          <div className='dividing-line'>
            <span>2</span>
          </div>

          <div className='info-block'>
            <h3 className='how-it-works-title'>Assess Your Needs</h3>
            <p>
              We work closely with you to understand the specific challenges and
              requirements your business faces.
            </p>
          </div>
        </div>

        <div className='steps-container'>
          <div className='info-block'>
            <h3 className='how-it-works-title'>Choose Your Options</h3>
            <p>
              Select from a range of customized services that best fit your
              operational needs and objectives.
            </p>
          </div>

          <div className='dividing-line'>
            <span>3</span>
          </div>

          <div className='illustration-box'>
            <img
              src='/illustrations/BusinessChat.svg'
              alt='Handle it'
            />
          </div>
        </div>

        <div className='steps-container'>
          <div className='illustration-box'>
            <img
              src='/illustrations/Collab.svg'
              alt='Contact us'
            />
          </div>

          <div className='dividing-line'>
            <span>4</span>
          </div>

          <div className='info-block'>
            <h3 className='how-it-works-title'>Let Us Handle It</h3>
            <p>
              Once you've made your choices, sit back and relax. Our expert team
              will take over, ensuring a seamless implementation and ongoing
              support.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
