import React from 'react';
import '../customCSS/footer.css';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

const year = new Date().getFullYear();

const Footer = () => {
  const navigate = useNavigate();

  /**
   * Handles the click event on the logo.
   * Navigates to the home page and smoothly scrolls to the top of the page.
   */
  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className='section-footer stevenmcginnis bransonberry'>
      <div className='container grid grid--footer'>
        {/* Logo Column */}
        <div className='logo-col'>
          <Link
            onClick={handleLogoClick}
            className='footer-logo'
            style={{ cursor: 'pointer' }}
            to='/'
          >
            <img
              src='itmlogo.svg'
              alt='ITM Marketing'
              className='footer-logo'
            />
          </Link>
          <ul className='social-links'>
            <li>
              <a
                rel='noreferrer noopener'
                target='_blank'
                aria-label='LinkedIn - ITM Marketing'
                className='footer-link'
                href='https://www.linkedin.com/company/itm-marketing-inc/mycompany/'
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li>
              <a
                rel='noreferrer noopener'
                target='_blank'
                aria-label='Facebook - ITM Marketing'
                className='footer-link'
                href='https://www.facebook.com/itmmarketing/'
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
          </ul>
          <p className='copyright'>
            Copyright &copy; {year} by ITM Marketing, Inc. All rights reserved.
          </p>
        </div>

        {/* Compliance Column with PCI DSS and HUBZone Badges */}
        <div className='compliance-col'>
          <img
            src='hubZone.svg'
            alt='HUBZone Certified'
            className='hubzone-badge'
          />
          <img
            src='pciCompliant.svg'
            alt='PCI DSS Compliant'
            className='compliance-badge'
          />
        </div>

        {/* Address/Contact Column */}
        <div className='address-col'>
          <p className='footer-heading'>Contact us</p>
          <address className='contacts'>
            <div className='contact-box'>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span className='address-text'>
                331 Main Street, Coshocton, OH 43812 United States
              </span>
            </div>
            <div className='contact-box'>
              <FontAwesomeIcon icon={faPhone} />
              <a
                aria-label='Call (800) 686-4486'
                className='footer-link'
                href='tel:+(800) 686-4486'
              >
                (800) 686-4486
              </a>
            </div>
            <div className='contact-box'>
              <FontAwesomeIcon icon={faPhone} />
              <a
                aria-label='Call (740) 295-3575'
                className='footer-link'
                href='tel:+(740) 295-3575'
              >
                (740) 295-3575
              </a>
            </div>
          </address>
        </div>

        {/* Company and Resources Columns */}
        <nav className='nav-col'>
          <p className='footer-heading'>Company</p>
          <ul className='footer-nav'>
            <li>
              <Link
                className='footer-link'
                to='/about-us'
              >
                About ITM
              </Link>
            </li>
            <li>
              <a
                rel='noreferrer noopener'
                target='_blank'
                className='footer-link'
                href='https://itmmarketing.applicantstack.com/x/openings'
              >
                Careers
              </a>
            </li>
            <li>
              <Link
                className='footer-link'
                to='/meet-the-team'
              >
                Meet the Team
              </Link>
            </li>
          </ul>
        </nav>

        <nav className='nav-col'>
          <p className='footer-heading'>Resources</p>
          <ul className='footer-nav'>
            <li>
              <Link
                to='/privacy-policy'
                className='footer-link'
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                className='footer-link'
                to='/terms-and-conditions'
              >
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
