// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './components/navigation';
import Footer from './components/footer';
import AppRoutes from './Routes';
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import { NextUIProvider } from '@nextui-org/react';
import ScrollToTop from './components/scrollToTop';
import './App.css';
import './Queries/mediaQueries.css';
setBasePath(
  'https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.17.1/cdn/'
);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NextUIProvider>
        <Navigation />
        <AppRoutes />
        <Footer />
      </NextUIProvider>
    </Router>
  );
}

export default App;
