import React from 'react';
import '../customCSS/privacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className='privacy-container'>
      <h1 className='privacy-title'>Privacy Policy</h1>
      <p className='privacy-effective-date'>Effective Date: 2024-10-31</p>

      <p className='privacy-intro'>
        ITM Marketing, Inc. ("ITM Marketing," "we," "us," or "our") is committed
        to protecting the privacy and security of your personal information.
        This Privacy Policy outlines the types of information we collect through
        our website, how we use and protect that information, and your choices
        regarding the collection and use of your information.
      </p>

      <section className='privacy-section'>
        <h2 className='privacy-heading'>1. Information We Collect</h2>
        <p className='privacy-text'>
          When you visit our website, we may collect certain information
          automatically, including your IP address, browser type, operating
          system, referring URLs, device information, and other usage
          information. We may also collect information you provide to us
          voluntarily through our contact form, including your name, email
          address, phone number, and any other information you choose to
          provide.
        </p>
      </section>

      <section className='privacy-section'>
        <h2 className='privacy-heading'>2. Use of Information</h2>
        <p className='privacy-text'>
          We may use the information we collect for various purposes, including
          to respond to your inquiries and requests submitted through our
          contact form, to improve and optimize our website, to analyze trends
          and usage patterns, and to enhance the security and functionality of
          our website.
        </p>
      </section>

      <section className='privacy-section'>
        <h2 className='privacy-heading'>3. Protection of Information</h2>
        <p className='privacy-text'>
          We take reasonable measures to protect the confidentiality, integrity,
          and security of your personal information. We use industry-standard
          security technologies and procedures to safeguard your information
          from unauthorized access, use, or disclosure.
        </p>
      </section>

      <section className='privacy-section'>
        <h2 className='privacy-heading'>4. Use of Cookies</h2>
        <p className='privacy-text'>
          We may use cookies and similar tracking technologies to collect
          information about your interactions with our website. Cookies are
          small text files stored on your device that help us analyze website
          traffic and improve your browsing experience. You can control cookies
          through your browser settings and preferences.
        </p>
      </section>

      <section className='privacy-section'>
        <h2 className='privacy-heading'>5. Third-Party Services</h2>
        <p className='privacy-text'>
          Our website may contain links to third-party websites or services that
          are not operated or controlled by ITM Marketing. This Privacy Policy
          does not apply to third-party websites or services, and we are not
          responsible for the privacy practices or content of those sites.
        </p>
      </section>

      <section className='privacy-section'>
        <h2 className='privacy-heading'>6. Changes to this Privacy Policy</h2>
        <p className='privacy-text'>
          We reserve the right to update or modify this Privacy Policy at any
          time, without prior notice. Any changes will be effective immediately
          upon posting the revised Privacy Policy on our website. We encourage
          you to review this Privacy Policy periodically for any updates or
          changes.
        </p>
      </section>

      <section className='privacy-section'>
        <h2 className='privacy-heading'>7. Consent</h2>
        <p className='privacy-text'>
          By using our website, you consent to the collection and use of your
          information as described in this Privacy Policy.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
