import React from 'react';
import '../customCSS/testimonials.css';

const Testimonials = () => {
  return (
    <div className='container'>
      <div className='section-heading testimonial-section-heading'>
        Testimonials
      </div>
      <div className='pricing-headings'>
        <h2 className='heading-secondary'>
          Discover Why Leading Businesses Choose ITM
        </h2>
      </div>
      <div className='testimonial-container'>
        <div className='testimonial'>
          <blockquote className='testimonial-text'>
            "ITM Marketing has been a trusted and reliable business partner
            these past 10 years. They have been responsive, effective and honest
            and they treat our customers just as we treat our customers. ITM
            really makes an effort to be a great partner helping us to optimize
            customer experience and increase revenues year over year."
          </blockquote>
          <p className='testimonial-author'>
            -Lesa, Customer Service Relations Director, Kirkland's, Inc.-
          </p>
        </div>
        <div className='testimonial-logo'>
          <img
            src='/companyLogos/KirklandsLogo.svg'
            alt="Kirkland's, Inc."
          />
        </div>

        <div className='testimonial-logo'>
          <img
            src='/companyLogos/PentonLogo.png'
            alt="Kirkland's, Inc."
          />
        </div>
        <div className='testimonial'>
          <blockquote className='testimonial-text'>
            "While my experience with the professionals at ITM has been entirely
            positive, we have been especially benefited by their IntelliView
            program that provides us with all project data live, in real time. A
            circulation manager from another firm states, It’s fantastic. I’ve
            never seen anything like this before in the industry."
          </blockquote>
          <p className='testimonial-author'>-VP, Penton Media Circulation-</p>
        </div>

        <div className='testimonial'>
          <blockquote className='testimonial-text'>
            "ITM provided a solution for our patient prospect calling program
            for a much more affordable cost, better real time online reporting
            solution and remote monitoring anytime of day. ITM has provided
            additional value added items that include an inbound member service
            line as well as leaving live person answering machines for no
            additional cost. No hidden fees and no reporting costs helped
            further our lower cost of fundraising without sacrificing average
            donations and rapport with our members. "
          </blockquote>
          <p className='testimonial-author'>
            -Director of Annual Fund, The Cleveland Clinic Foundation-
          </p>
        </div>
        <div className='testimonial-logo'>
          <img
            src='/companyLogos/ClevelandCLogo.svg'
            alt="Kirkland's, Inc."
          />
        </div>

        <div className='award-section'>
          <div className='award'>
            <img
              src='/awards/CloudComputingAward.png'
              alt='Award 1'
            />
          </div>
          <div className='award'>
            <img
              src='/awards/ContactCenterAward.png'
              alt='Award 2'
            />
          </div>
          <div className='award'>
            <img
              src='/awards/CustomerProductAward.png'
              alt='Award 3'
            />
          </div>
          <div className='award'>
            <img
              src='/awards/RemoteWorkAward.png'
              alt='Award 4'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
